import React, { useState } from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

import HoverableAnimation from "components/common/HoverableAnimation"

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 230px 1fr;
  grid-gap: 5px;
  align-items: flex-start;
  color: ${({ theme }) => theme.color.text.inverted};
  padding: 0 2rem 2rem 2rem;
  text-align: center;
  height: 100%;
  width: 100%;
  text-transform: uppercase;
  position: relative;
  z-index: 0;

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 335px;
    width: 335px;
    z-index: -1;
    border-radius: 100%;
    box-shadow: ${({ theme }) => theme.shadow.light};
    ${({ theme }) => theme.mq.s} {
      display: block;
    }
    ${({ theme }) => theme.mq.s} {
      bottom: 0;
    }
    ${({ theme }) => theme.mq.xs} {
      top: 25%;
      height: 240px;
      width: 240px;
    }
  }
  // FIRST ELEMENT
  &:nth-child(3n - 2) {
    &:after {
      background-color: ${({ theme }) => theme.color.cyan_600};
    }
  }
  // SECOND ELEMENT
  &:nth-child(3n - 1) {
    &:after {
      background-color: ${({ theme }) => theme.color.cyan_500};
    }
  }
  // THIRD ELEMENT
  &:nth-child(3n) {
    &:after {
      background-color: ${({ theme }) => theme.color.cyan_300};
    }
  }
  ${({ theme }) => theme.mq.s} {
    &:nth-of-type(odd) {
      &:after {
        background-color: ${({ theme }) => theme.color.cyan_500};
      }
    }
    &:nth-of-type(even) {
      &:after {
        background-color: ${({ theme }) => theme.color.cyan_500};
      }
    }
  }
  ${({ theme }) => theme.mq.xs} {
    &:nth-child(n) {
      &:after {
        background-color: ${({ theme }) => theme.color.cyan_500};
      }
    }
  }

  h3 {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 900;
    ${({ theme }) => theme.mq.xs} {
      font-size: 1.8rem;
      line-height: 1.8rem;
    }
  }
`

const MOBILE_REDUCE_FACTOR = 0.75

const ServicesElement = ({ title, animationData, scale, initialIconPosition, finalIconPosition, mobile }) => {
  const [playingState, setPlayingState] = useState("stopped")
  const [hovered, setHovered] = useState(false)
  const [, setIsPlaying] = useState(false)
  const play = () => {
    setIsPlaying(true)
    setPlayingState("playing")
  }
  const handleLottieMouseEnter = () => {
    setHovered(true)
    play()
  }

  const handleLottieMouseLeave = () => {
    setHovered(false)
    play()
  }

  return (
    <StyledWrapper onMouseEnter={handleLottieMouseEnter} onMouseLeave={handleLottieMouseLeave}>
      <HoverableAnimation
        animationData={animationData}
        direction={hovered ? 1 : -1}
        finalIconPosition={finalIconPosition}
        hovered={hovered}
        initialIconPosition={initialIconPosition}
        playingState={playingState}
        scale={mobile ? scale * MOBILE_REDUCE_FACTOR : scale}
        setIsPlaying={setIsPlaying}
      />
      <h3>{title}</h3>
    </StyledWrapper>
  )
}

ServicesElement.propTypes = {
  animationData: PropTypes.shape({}).isRequired,
  initialIconPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  finalIconPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  title: PropTypes.string.isRequired,
  scale: PropTypes.number.isRequired,
  mobile: PropTypes.bool,
}

ServicesElement.defaultProps = {
  mobile: false,
}

export default ServicesElement
