import React from "react"

import PropTypes from "prop-types"
import { animated } from "react-spring"
import styled from "styled-components"

const StyledBenefitsElement = styled.article`
  text-align: center;
  color: ${({ theme }) => theme.color.text.inverted};
  padding: 5rem;

  h3 {
    text-transform: uppercase;
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: 900;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  &:nth-child(1) {
    background: ${({ theme: { color } }) =>
      `transparent linear-gradient(90deg, ${color.cyan_400} 0%, ${color.cyan_500} 57%, ${color.cyan_600} 100%) 0% 0%`};
  }
  &:nth-child(2) {
    background: ${({ theme: { color } }) =>
      `transparent linear-gradient(90deg, ${color.cyan_700} 0%, ${color.cyan_800} 57%, ${color.cyan_900} 100%) 0% 0%`};
  }
  &:nth-child(3) {
    background: ${({ theme: { color } }) =>
      `transparent linear-gradient(90deg, ${color.purple_500} 0%, ${color.purple_600} 57%, ${color.purple_600} 100%) 0% 0%`};
  }

  ${({ theme }) => theme.mq.xs} {
    &:nth-child(1) {
      background: ${({ theme: { color } }) =>
        `transparent linear-gradient(180deg, ${color.cyan_400} 0%, ${color.cyan_500} 57%, ${color.cyan_600} 100%) 0% 0%`};
    }
    &:nth-child(2) {
      background: ${({ theme: { color } }) =>
        `transparent linear-gradient(180deg, ${color.cyan_700} 0%, ${color.cyan_800} 57%, ${color.cyan_900} 100%) 0% 0%`};
    }
    &:nth-child(3) {
      background: ${({ theme: { color } }) =>
        `transparent linear-gradient(180deg, ${color.purple_500} 0%, ${color.purple_600} 57%, ${color.purple_600} 100%) 0% 0%`};
    }
  }
`

const BenefitsElement = ({ title, content, style }) => (
  <StyledBenefitsElement as={animated.div} style={style}>
    <h3>{title}</h3>
    <p>{content}</p>
  </StyledBenefitsElement>
)

BenefitsElement.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  style: PropTypes.shape({}).isRequired,
}

export default BenefitsElement
