import React from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

import Button from "components/common/Button"
import Grid from "components/common/Grid"

const StyledGrid = styled(Grid)`
  color: ${({ theme }) => theme.color.text.inverted};
  padding: 30px;
  ${({ theme }) => theme.gradient.linear.cyan_light_to_dark};
  text-align: center;
  height: 100%;

  h3 {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    line-height: 16px;
  }
`

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  ${({ theme }) => theme.mq.s} {
    justify-content: center;
  }
`

const StyledIcon = styled.div`
  display: block;
  height: 100px;
  margin: 0 auto;
`

const AboutTile = ({ title, content, icon, to, buttonLabel }) => (
  <StyledGrid columns="1fr" gap="2.5rem">
    <StyledIcon as={icon} />
    <h3>{title}</h3>
    <p>{content}</p>
    <StyledButtonWrapper>
      <Button aria-label={`Read more about ${title}`} fontSize="11px" to={to}>
        {buttonLabel}
      </Button>
    </StyledButtonWrapper>
  </StyledGrid>
)

AboutTile.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
}

AboutTile.defaultProps = {
  buttonLabel: "Read more",
}

export default AboutTile
