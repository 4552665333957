import React from "react"

// possible to back to source version and use lottie-light when PR be merged https://github.com/chenqingspring/react-lottie/pull/90
import { Lottie } from "@alfonmga/react-lottie-light-ts"
import PropTypes from "prop-types"
import styled from "styled-components"

const defaultOptions = {
  loop: false,
  autoplay: false,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const StyledWrapper = styled.div`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  display: block;
  margin: 0 auto;
  transition: 0.35s 0.1s ease-in-out;
  transform: ${({ hovered, initX, finalX, initY, finalY }) =>
    hovered ? `translate(${finalX}px, ${finalY}px)` : `translate(${initX}px, ${initY}px)`};
  align-self: end;
`

const HoverableAnimation = ({
  hovered,
  playingState,
  direction,
  setIsPlaying,
  animationData,
  scale,
  finalIconPosition,
  initialIconPosition,
}) => (
  <StyledWrapper
    finalX={finalIconPosition.x || 0}
    finalY={finalIconPosition.y || 0}
    height={animationData.h * scale}
    hovered={hovered}
    initX={initialIconPosition.x || 0}
    initY={initialIconPosition.y || 0}
    width={animationData.w * scale}
  >
    <Lottie
      config={{ ...defaultOptions, animationData }}
      direction={direction}
      lottieEventListeners={[
        {
          name: "complete",
          callback: () => setIsPlaying && setIsPlaying(false), // use it to display complete animations always
        },
      ]}
      playingState={playingState}
      speed={2}
    />
  </StyledWrapper>
)

HoverableAnimation.defaultProps = {
  scale: 1,
}

HoverableAnimation.propTypes = {
  scale: PropTypes.number,
  playingState: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  setIsPlaying: PropTypes.func.isRequired,
  animationData: PropTypes.string.isRequired,
  initialIconPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  finalIconPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  hovered: PropTypes.bool.isRequired,
}

export default HoverableAnimation

// EVENTS:

// onComplete
// onLoopComplete
// onEnterFrame
// onSegmentStart
// you can also use addEventListener with the following events:
//
// complete
// loopComplete
// enterFrame
// segmentStart
// config_ready (when initial config is done)
// data_ready (when all parts of the animation have been loaded)
// data_failed (when part of the animation can not be loaded)
// loaded_images (when all image loads have either succeeded or errored)
// DOMLoaded (when elements have been added to the DOM)
// destroy
