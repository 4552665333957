export default {
  hero: {
    title: "Software <br /> House",
    subtitle: "Specializing in PYTHON & .NET",
    subtitle_description: "Our full stack development team can help you make a great product!",
  },
  about: [
    {
      icon: "project",
      title: "Project Development",
      subtitle:
        "Let your ideas be seen. For more than 11 years, we create with our clients optimized business applications.",
      to: "/",
    },
    {
      icon: "start_up",
      title: "Startup MVP build and consulting",
      subtitle:
        "Do you want to verify the market with your app before going big? MVP is the answer. Let's get it done!",
      to: "/",
    },
    {
      icon: "team",
      title: "Team extension",
      subtitle: "Are you short on people in Python&.NET and want to scale up without hurting your pocket?",
      to: "/",
    },
  ],
  counters: {
    market: {
      value: {
        numeric: 11,
        rest: null,
      },
      title: "years on the market",
    },
    projects: {
      value: {
        numeric: 100,
        rest: "+",
      },
      title: "delivered projects",
    },
    members: {
      value: {
        numeric: 25,
        rest: null,
      },
      title: "team members",
    },
    delivery: {
      value: {
        numeric: 8,
        rest: null,
      },
      subValue: " months",
      title: "average project delivery time",
    },
    rating: {
      value: {
        numeric: 4.6,
        rest: "/5",
      },
      title: "our company rating",
    },
    clients: {
      value: {
        numeric: 70,
        rest: null,
      },
      subValue: "%",
      title: "of clients return with more work",
    },
  },
  services: {
    title: "What we do",
    web: {
      title: "web applications",
    },
    mobile: {
      title: "mobile applications",
    },
    desktop: {
      title: "desktop applications",
    },
  },
  implementations: {
    title: "Our latest implementations",
    projects: {
      project: {
        title: "project development",
        subtitle: "design, ux, backend",
        to: "/",
      },
      condo: {
        title: "Rynek Condo",
        subtitle: "Design, UX, Backend, Mobile",
        to: "/",
      },
      mobile: {
        title: "mobile app platform",
        subtitle: "Design, UX, Backend, Mobile",
        to: "/",
      },
      rippler: {
        title: "rippler",
        subtitle: "Design, UX, CMS, CRM",
        to: "/",
      },
      eatzone: {
        title: "eatzone",
        subtitle: "Graphic design, Kinect, Sound",
        to: "/",
      },
      lorem: {
        title: "lorem",
        subtitle: "lorem",
        to: "/",
      },
      hop: {
        title: "Hop4elit",
        subtitle: "Design, UX, CMS, CRM",
        to: "/",
      },
    },
  },
}
