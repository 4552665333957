import React, { useState } from "react"

import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import Container from "components/common/Container"
import Grid from "components/common/Grid"
import Section from "components/common/Section"
import CounterElement from "components/pages/home/Counters/_components/CounterElement"

const StyledSection = styled(Section)`
  ${({ theme }) => theme.gradient.mixed.blue};
  padding: 25rem 0;
  ${({ theme }) => theme.mq.xs} {
    padding: 9.2rem 0;
  }
`

const query = graphql`
  query counterQuery {
    strapiHome {
      counters {
        additional_value
        id
        label
        unit
        value
      }
    }
  }
`

const Counters = () => {
  const [inView, setInView] = useState(false)
  const { strapiHome } = useStaticQuery(query)
  return (
    <StyledSection delay="40%" intersected onIntersectChange={setInView}>
      <Container>
        <Grid columns="1fr 1fr 1fr" columns_s="1fr 1fr" gap="10rem 5rem" gap_s="6.5rem 0" justify="center">
          {strapiHome.counters.map((counter, index) => (
            <CounterElement key={counter.id} index={index} inView={inView} {...counter} />
          ))}
        </Grid>
      </Container>
    </StyledSection>
  )
}

export default Counters
