import React, { useState } from "react"

import { graphql, useStaticQuery } from "gatsby"
import { useTrail } from "react-spring"
import styled from "styled-components"

import Container from "components/common/Container"
import Section from "components/common/Section"

import BenefitsElement from "./_components/BenefitsElement"

const query = graphql`
  query BenefitsQuery {
    strapiHome {
      benefits {
        section_title
        benefits {
          id
          content
          title
        }
      }
    }
  }
`

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  ${({ theme }) => theme.mq.xs} {
    max-width: 400px;
    margin: 0 auto;
    grid-template-columns: auto;
  }
`

const Benefits = () => {
  const { strapiHome } = useStaticQuery(query)
  const { benefits } = strapiHome.benefits
  const [isVisible, setIsVisible] = useState(false)

  const trail = useTrail(benefits.length, {
    config: { mass: 1, tension: 180, friction: 20 },
    opacity: isVisible ? 1 : 0,
    y: isVisible ? 0 : 400,
  })
  return (
    <Section
      animatedTitle={strapiHome.benefits.section_title}
      color="white"
      intersected
      onIntersectChange={setIsVisible}
      padding="7rem 0 14rem"
      padding_s="7rem 0"
    >
      <Container>
        <StyledContainer>
          {trail.map(({ y, ...rest }, index) => {
            const { id, content, title } = benefits[index]
            return (
              <BenefitsElement
                key={id}
                content={content}
                style={{ ...rest, transform: y.interpolate(dy => `translate3d(0,${dy}px,0)`) }}
                title={title}
              />
            )
          })}
        </StyledContainer>
      </Container>
    </Section>
  )
}

export default Benefits
