import React from "react"

import PropTypes from "prop-types"

import TransitionWrapper from "components/common/Layout"
import Opinions from "components/common/Opinions"
import Seo from "components/common/Seo"
import TrustUs from "components/common/TrustUs"
import About from "components/pages/home/About"
import Benefits from "components/pages/home/Benefits"
import Counters from "components/pages/home/Counters"
import Hero from "components/pages/home/Hero"
import Implementations from "components/pages/home/Implementations"
import Services from "components/pages/home/Services"

const HomePage = ({ location }) => (
  <TransitionWrapper location={location}>
    <Seo seoPageName="General" />
    <Hero />
    <About />
    <Counters />
    <Services />
    <Opinions isVideo />
    <TrustUs />
    <Implementations />
    <Benefits />
  </TransitionWrapper>
)

HomePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default HomePage
