import React, { useCallback, useEffect, useState } from "react"

import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { throttle } from "lodash"
import { config, useSpring, animated } from "react-spring"

import ArrowDown from "assets/svg/arrow_down.svg"
import ClutchReview from "components/common/ClutchReview"
import Container from "components/common/Container"
import Grid from "components/common/Grid"
import Portal from "components/common/Portal"
import urls from "config/urls"
import { useGlobalDataContext } from "context/GlobalDataContext"
import useWindowWidth from "helpers/useWindowWidth"
import { mq } from "styles/theme"

import {
  StyledFilter,
  StyledHero,
  StyledHeroContentBox,
  StyledLogo,
  StyledHeroDescription,
  StyledGatsbyImg,
  StyledVideo,
  StyledLetsTalk,
  StyledShape,
  StyledDescription,
  StyledHeroContent,
  StyledMobileWrapper,
  StyledArrow,
  StyledClutchMobile,
  StyledClutchMobileLogo,
} from "./Hero.styles"

const query = graphql`
  query heroQuery {
    hero: file(relativePath: { eq: "background/hero_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    strapiHome {
      hero {
        desc_1
        desc_2
        title
      }
    }
  }
`

const initLetsTalkPosition = {
  height: 150,
  width: 150,
  top: 150,
  right: 100,
  position: "absolute",
  borderRadius: 100,
  fontSize: 35,
}

const Hero = () => {
  const { hero, strapiHome } = useStaticQuery(query)
  const windowWidth = useWindowWidth()

  const isCSR = typeof window !== "undefined"

  // LET'S TALK LOGIC
  const {
    letsTalk: { setLetsTalkStickOn, setLetsTalkStickOff, isStick },
  } = useGlobalDataContext()
  const contactLinkPosition = () => {
    if (isCSR) {
      const element = document.querySelector("#contact")
      return element ? element.getBoundingClientRect() : {}
    }
    return {}
  }
  const [letsTalkPosition, setLetsTalkPosition] = useState(initLetsTalkPosition)
  const letsTalkSpring = useSpring({
    config: config.stiff,
    ...letsTalkPosition,
  })
  const animateLetsTalk = useCallback(() => {
    if (window.scrollY > initLetsTalkPosition.top / 4) {
      setLetsTalkStickOn()
      const { top, left, width, height } = contactLinkPosition()
      const right = window.innerWidth - left - width - 15
      setLetsTalkPosition({
        top,
        right,
        position: "fixed",
        width,
        height,
        borderRadius: 5,
        fontSize: 16,
      })
    } else {
      setLetsTalkStickOff()
      setLetsTalkPosition(initLetsTalkPosition)
    }
  }, [])

  // TRIANGLES LOGIC
  const [scrollTop, setScrollTop] = useState(0)
  const trianglesSpring = useSpring({
    config: config.stiff,
    scroll: scrollTop,
  })
  const animateTriangles = useCallback(() => {
    const { scrollY, innerHeight } = window
    if (scrollY <= innerHeight) {
      setScrollTop(scrollY)
    }
  }, [])

  // SCROLL LISTEN LOGIC
  const onScroll = useCallback(() => {
    if (isCSR) {
      if (windowWidth > mq.xs) {
        animateLetsTalk()
        animateTriangles()
      }
    }
  }, [animateTriangles, isCSR, windowWidth])

  const scrollDown = () => window.scrollTo({ top: window.innerHeight - 60, behavior: "smooth" })

  useEffect(() => {
    if (isCSR) {
      const throttledOnScroll = throttle(onScroll, 50)
      window.addEventListener("scroll", throttledOnScroll)
      return () => window.removeEventListener("scroll", throttledOnScroll)
    }
    return null
  }, [onScroll, isCSR])

  return (
    <StyledHero fluid={hero.childImageSharp.fluid}>
      <StyledMobileWrapper>
        {windowWidth > mq.xs && (
          <StyledVideo autoPlay loop muted poster="video/poster_blurred.webp">
            <StyledFilter />
            <source src="video/bg_v2.mp4" type="video/mp4" />
          </StyledVideo>
        )}
        <StyledFilter />
        <StyledHeroContentBox>
          <Container>
            <StyledHeroContent>
              <StyledHeroDescription>
                <StyledLogo alt="JMR" />
                {/* eslint-disable-next-line react/no-danger */}
                <h1 dangerouslySetInnerHTML={{ __html: strapiHome.hero.title }} />
                <h2>{strapiHome.hero.desc_1}</h2>
                <StyledDescription>{strapiHome.hero.desc_2}</StyledDescription>
              </StyledHeroDescription>
              {windowWidth > mq.xs ? (
                <Grid columns="1fr 1fr" gap="3rem" justify_s="end">
                  <StyledGatsbyImg>
                    <StaticImage
                      alt="Deliver on Clutch"
                      loading="lazy"
                      placeholder="tracedSVG"
                      src="../../../../assets/images/clutch2023.png"
                    />
                  </StyledGatsbyImg>
                  <StyledGatsbyImg>
                    <StaticImage
                      alt="Deliver on Clutch"
                      loading="lazy"
                      placeholder="tracedSVG"
                      src="../../../../assets/images/clutch2019.png"
                    />
                  </StyledGatsbyImg>
                  <ClutchReview />
                </Grid>
              ) : (
                <StyledArrow onClick={scrollDown}>
                  <ArrowDown />
                </StyledArrow>
              )}
            </StyledHeroContent>
          </Container>
          <StyledShape
            style={{
              willChange: "transform",
              transform:
                windowWidth <= mq.xs
                  ? `translate(-77%, -25%) rotate(25deg)`
                  : trianglesSpring.scroll.interpolate(
                      d => `translate(-${50 + d * 0.015}%, ${10 + d * 0.01}%) rotate(${10 + d * 0.008}deg)`
                    ),
            }}
          />
          <StyledShape
            style={{
              willChange: "transform",
              transform:
                windowWidth <= mq.xs
                  ? `translate(-77%, -30%) rotate(25deg)`
                  : trianglesSpring.scroll.interpolate(
                      d => `translate(-${50 + d * 0.01}%, ${15 + d * 0.02}%) rotate(${10 + d * 0.005}deg)`
                    ),
            }}
          />
          <StyledShape
            cyan
            style={{
              willChange: "transform",
              transform:
                windowWidth <= mq.xs
                  ? `translate(-70%, 80%) rotate(-25deg)`
                  : trianglesSpring.scroll.interpolate(
                      d => `translate(-${50 + d * 0.005}%, ${30 + d * 0.005}%) rotate(-${12 + d * 0.005}deg)`
                    ),
            }}
          />
        </StyledHeroContentBox>
        {windowWidth > mq.xs ? (
          <Portal>
            <Link id="lets-talk-button" to={urls.contact.url}>
              <StyledLetsTalk
                as={animated.div}
                isStick={isStick}
                style={{ willChange: "top, right, width, height, border-radius, font-size", ...letsTalkSpring }}
              >
                <span>Let&apos;s talk!</span>
              </StyledLetsTalk>
            </Link>
          </Portal>
        ) : (
          <Link to={urls.contact.url}>
            <StyledLetsTalk isStick={false}>
              <span>Let&apos;s talk!</span>
            </StyledLetsTalk>
          </Link>
        )}
      </StyledMobileWrapper>
      {windowWidth <= mq.xs && (
        <StyledClutchMobile>
          <StyledClutchMobileLogo>
            <StyledGatsbyImg>
              <StaticImage
                alt="Deliver on Clutch"
                loading="lazy"
                placeholder="tracedSVG"
                src="../../../../assets/images/clutch2023.png"
              />
            </StyledGatsbyImg>
            <StyledGatsbyImg>
              <StaticImage
                alt="Deliver on Clutch"
                loading="lazy"
                placeholder="tracedSVG"
                src="../../../../assets/images/clutch2019.png"
              />
            </StyledGatsbyImg>
          </StyledClutchMobileLogo>
          <ClutchReview />
        </StyledClutchMobile>
      )}
    </StyledHero>
  )
}

export default Hero
