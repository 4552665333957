import React, { useState } from "react"

import { graphql, useStaticQuery } from "gatsby"
import { useTrail, animated, config } from "react-spring"

import IconProject from "assets/svg/icon_project.svg"
import IconStartUp from "assets/svg/icon_startup.svg"
import IconTeam from "assets/svg/icon_team.svg"
import Container from "components/common/Container"
import Grid from "components/common/Grid"
import Section from "components/common/Section"
import AboutTile from "components/pages/home/About/_components/AboutTile"

const query = graphql`
  query OffersQuery {
    strapiHome {
      offer {
        section_title
        offers {
          id
          link
          title
          content
        }
      }
    }
  }
`

const resolveIcon = icon => {
  switch (icon) {
    case 0:
      return IconProject
    case 1:
      return IconStartUp
    case 2:
      return IconTeam
    default:
      return IconProject
  }
}

const About = () => {
  const { strapiHome } = useStaticQuery(query)
  const [isVisible, setIsVisible] = useState(false)
  const trail = useTrail(strapiHome.offer.offers.length, {
    config: config.stiff,
    opacity: isVisible ? 1 : 0,
    y: isVisible ? 0 : 300,
  })
  return (
    <Section
      animatedTitle={strapiHome.offer.section_title}
      color="white"
      hideTitleOnDesktop
      intersected
      onIntersectChange={setIsVisible}
    >
      <Container>
        <Grid columns="1fr 1fr 1fr" columns_s="1fr" gap="5rem" gap_s="2rem">
          {trail.map(({ y, ...rest }, index) => {
            const { id, title, content, link } = strapiHome.offer.offers[index]
            return (
              <animated.div
                key={id}
                style={{ ...rest, height: "100%", transform: y.interpolate(dy => `translate3d(0,${dy}px,0)`) }}
              >
                <AboutTile content={content} icon={resolveIcon(index)} title={title} to={link} />
              </animated.div>
            )
          })}
        </Grid>
      </Container>
    </Section>
  )
}

export default About
