import React from "react"

import PropTypes from "prop-types"
import { useSpring, animated, config } from "react-spring"
import styled from "styled-components"

import Grid from "components/common/Grid"

const StyledGrid = styled(Grid)`
  color: ${({ theme }) => theme.color.text.inverted};
  padding: 0 50px;
  text-align: center;
  height: 100%;
  text-transform: uppercase;
  ${({ theme }) => theme.mq.xs} {
    padding: 0 10px;
  }

  h3 {
    font-family: ${({ theme }) => theme.font.fatfrank};
    font-size: 8rem;
    line-height: 8rem;
    ${({ theme }) => theme.mq.xs} {
      font-size: 4.5rem;
      line-height: 4.5rem;
    }
    span {
      margin-left: 0.5rem;
      font-family: ${({ theme }) => theme.font.fatfrank};
    }
  }
  p {
    font-family: ${({ theme }) => theme.font.azo_sans_web};
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 900;
    max-width: 200px;
    ${({ theme }) => theme.mq.xs} {
      max-width: 140px;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
`

const StyledSubValue = styled.span`
  font-family: ${({ theme }) => theme.font.azo_sans_web};
  font-size: 18px;
  line-height: 18px;
  font-weight: 900;
  ${({ theme }) => theme.mq.xs} {
    font-size: 16px;
    line-height: 16px;
  }
`

const isIntegerCheck = n => n % 1 === 0

const CounterElement = ({ value, additional_value, label, inView, index, unit }) => {
  const numericValue = Number(value)
  const { number } = useSpring({
    config: config.stiff,
    number: inView ? numericValue : 0,
    from: { number: 0 },
    delay: 120 * index,
  })
  const isInteger = isIntegerCheck(numericValue)

  return (
    <StyledGrid align="start" columns="1fr" gap="2rem" gap_s="3rem">
      <h3>
        <animated.span>{number.interpolate(x => x.toFixed(isInteger ? 0 : 1))}</animated.span>
        {additional_value && additional_value}
        {unit && <StyledSubValue>{unit}</StyledSubValue>}
      </h3>
      <p>{label}</p>
    </StyledGrid>
  )
}

CounterElement.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  inView: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  additional_value: PropTypes.string,
  unit: PropTypes.string,
}

CounterElement.defaultProps = {
  additional_value: null,
  unit: null,
}

export default CounterElement
