import React from "react"

import styled from "styled-components"

import Button from "components/common/Button"
import urls from "config/urls"

const MAX_TILE_HEIGHT = 380

const StyledTile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.text.inverted};
  text-align: center;
  height: ${MAX_TILE_HEIGHT}px;
  max-height: ${MAX_TILE_HEIGHT}px;
  width: 100%;
  text-transform: uppercase;
  position: relative;
  background-color: ${({ theme }) => theme.color.black};
  overflow: hidden;
  ${({ theme }) => theme.gradient.linear.cyan_dark_to_light};

  @media (max-width: 620px) {
    height: 150px;
    max-height: 150px;
  }

  h3 {
    font-size: 1.7rem;
    line-height: 1.9rem;
    font-weight: 900;
    margin-bottom: 3rem;
    z-index: 1;
  }

  &::before {
    content: "";
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    ${({ theme }) => theme.gradient.linear.cyan_light_to_dark};
    transition: 0.45s;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
`

const ImplementationBlankElement = () => (
  <StyledTile>
    <h3>Not enough?</h3>
    <Button aria-label="More implementations" color="purple" padding="15px 30px" to={urls.portfolio.url}>
      See more
    </Button>
  </StyledTile>
)

export default ImplementationBlankElement
