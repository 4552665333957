import PropTypes from "prop-types"
import styled from "styled-components"

const StyledGrid = styled.div`
  display: ${({ display }) => display || "grid"};
  grid-template-columns: ${({ columns }) => columns};
  grid-gap: ${({ gap }) => gap};
  align-items: ${({ align }) => align};
  justify-items: ${({ justify }) => justify};
  justify-self: ${({ justify_self }) => justify_self};
  grid-template-rows: ${({ rows }) => rows};
  padding: ${({ padding }) => padding};
  ${({ theme }) => theme.mq.s} {
    justify-items: ${({ justify_s }) => justify_s};
    justify-self: ${({ justify_self_s }) => justify_self_s};
    grid-template-columns: ${({ columns_s }) => columns_s};
    grid-template-rows: ${({ rows_s }) => rows_s};
    grid-gap: ${({ gap_s }) => gap_s};
  }
  ${({ theme }) => theme.mq.xs} {
    justify-items: ${({ justify_xs }) => justify_xs};
    grid-template-columns: ${({ columns_xs }) => columns_xs};
  }
  ${({ theme }) => theme.mq.xxs} {
    grid-template-columns: ${({ columns_xxs }) => columns_xxs};
    grid-template-rows: ${({ rows_xxs }) => rows_xxs};
    justify-items: ${({ justify_xxs }) => justify_xxs};
    grid-gap: ${({ gap_xxs }) => gap_xxs};
    padding: ${({ padding_xxs }) => padding_xxs};
  }
`

StyledGrid.propTypes = {
  columns: PropTypes.string,
  rows: PropTypes.string,
  rows_s: PropTypes.string,
  rows_xxs: PropTypes.string,
  columns_s: PropTypes.string,
  columns_xs: PropTypes.string,
  columns_xxs: PropTypes.string,
  gap: PropTypes.string,
  gap_s: PropTypes.string,
  gap_xxs: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
  justify_s: PropTypes.string,
  justify_xs: PropTypes.string,
  justify_xxs: PropTypes.string,
  justify_self: PropTypes.string,
  justify_self_s: PropTypes.string,
  padding: PropTypes.string,
  padding_xxs: PropTypes.string,
}

StyledGrid.defaultProps = {
  columns: "auto",
  gap: "1rem",
  align: "center",
}

export default StyledGrid
