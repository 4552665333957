import React from "react"

import styled from "styled-components"

import animationDesktopRocket from "assets/animations/animation_desktop_rocket.json"
import animationMobileRocket from "assets/animations/animation_mobile_rocket.json"
import animationWebRocket from "assets/animations/animation_web_rocket.json"
import Container from "components/common/Container"
import Section from "components/common/Section"
import home from "content/home"
import useWindowWidth from "helpers/useWindowWidth"
import { mq } from "styles/theme"

import ServicesElement from "./_components/ServicesElement"

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12rem 5rem;
  justify-items: center;
  ${({ theme }) => theme.mq.m} {
    grid-gap: 10rem 3rem;
  }
  ${({ theme }) => theme.mq.s} {
    grid-template-columns: 1fr 1fr;
  }
  ${({ theme }) => theme.mq.xs} {
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    padding-bottom: 10rem;
    margin-top: -50px;
  }
`

const { services } = home

const Services = () => {
  const windowWidth = useWindowWidth()

  return (
    <Section animatedTitle={services.title} color="cyan" padding="7rem 0 14rem" padding_s="7rem 0">
      <Container>
        {windowWidth && windowWidth <= mq.xs ? (
          <StyledGrid>
            <ServicesElement
              animationData={animationWebRocket}
              finalIconPosition={{ x: -15, y: -5 }}
              initialIconPosition={{ x: -10, y: 29 }}
              mobile
              scale={0.5}
              title={services.web.title}
            />
            <ServicesElement
              animationData={animationMobileRocket}
              finalIconPosition={{ y: -20 }}
              initialIconPosition={{ y: -10 }}
              mobile
              scale={0.5}
              title={services.mobile.title}
            />
            <ServicesElement
              animationData={animationDesktopRocket}
              finalIconPosition={{ y: 5 }}
              initialIconPosition={{ y: 22 }}
              mobile
              scale={0.55}
              title={services.desktop.title}
            />
          </StyledGrid>
        ) : (
          <StyledGrid>
            <ServicesElement
              animationData={animationWebRocket}
              finalIconPosition={{ x: -15, y: -5 }}
              initialIconPosition={{ x: -10, y: 29 }}
              scale={0.5}
              title={services.web.title}
            />
            <ServicesElement
              animationData={animationMobileRocket}
              finalIconPosition={{ y: -20 }}
              initialIconPosition={{ y: -10 }}
              scale={0.5}
              title={services.mobile.title}
            />
            <ServicesElement
              animationData={animationDesktopRocket}
              finalIconPosition={{ y: 5 }}
              initialIconPosition={{ y: 22 }}
              scale={0.55}
              title={services.desktop.title}
            />
          </StyledGrid>
        )}
      </Container>
    </Section>
  )
}

export default Services
